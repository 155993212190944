<template>
    <PageHeader :title="title" />

    <!-- компонент таблицы -->
    <tablecustom
        :typesearch="typesearch" 
        :dataParams="dataParams"
        :objParams="objParams"
        @search="searchB" 
        :columns="columns" 
        :rows="rows"
        :pages="objPages"
        :showpag="true"
        @open="open" 
        @getdata="getdata"
        @changelimit="changelimit"
        @changeFilter="changeFilter" 
    />

    <viewcard 
      v-if="showModal == true" 
      @close="showModal = false" 
      :objCard="objCard"
    ></viewcard>
    
</template>

<script>
import PageHeader from "@/components/page-header";
import tablecustom from '@/components/globaltable/index';
import viewcard from './view/index';
import { Unregistered } from '@/API.js';
import { storeS } from "@/store";
import { getFlag } from '@/usabilityScripts/globalMutate.js'

let apiServe = new Unregistered();

export default {
components: {
    PageHeader,
    tablecustom,
    viewcard
},
data() {
    return {
        title: this.$t('AllAccounts'),
        search: '',
        showModal: false,
        dataParams: {
            status: false,
            page: "AllAccounts"
        },
        objParams:{
            page: '1',
            pagelimit: '10',
            search: '',
        },
        columns: [
            {
                name: 'ID',
                text: "id",
                align: "left",
                status: false
            },
            {
                name: this.$t("country"),
                text: "country",
                align: "left",
                status: true,
                mutate: (item) => this.getFlag(item.title)
            },
            {
                name: this.$t("name"),
                text: "userName",
                align: "left",
                status: true,
                mutate: (item) => item ? item : '-'
            },
            {
                name: this.$t("phone_num"),
                text: "userPhone",
                align: "right",
                status: true
            },
            {
                name: this.$t("mail"),
                text: "userEmail",
                align: "right",
                status: true,
                mutate: (item) => item ? item : '-'
            },
        ],
        rows: [],
        objPages: {},
        objCard: {},
    };
},
created() {
    this.getdata();
},
methods: {
    getdata(page){
        // phone accountId email
        // this.objParams.search = this.objParams.search == undefined ? "" : this.objParams.search
        // let method = 'phone';
        // if(this.objParams.search != undefined && this.objParams.search.length < 13) {
        //     method = 'phone'
        // } else if(this.objParams.search != undefined && this.objParams.search.includes('-')){
        //     method = 'accountId'
        // } else if(this.objParams.search != undefined && this.objParams.search.includes('@')){
        //     method = 'email'
        // }
        this.objParams.page = page != undefined ? page : '1';
        apiServe.getAccounts(this.objParams).then(result => {
            if(result.status == 'done') {
                this.rows = result.data.items
                this.objPages = result.data;
            } else {
                this.$toast.error(this.$t('error') + "# 1800");
            }
        })
    },
    open: function(e){
        apiServe.getAccount(e.id).then(result => {
            if(result.status == 'done') {
                this.objCard = result.data
                this.showModal = true
            } else {
                this.$toast.error(this.$t('error') + "# 1800")
            }
        })
    },
    changeFilter(name, value){
        console.log('name || value', name + ' || ' + value);
        if(!name.type && value != undefined){
            if(name == 'pr'){
                this.objParams['pr1'] = value[0];
                this.objParams['pr2'] = value[1];
            } else if (name == 'countryFilter') {
                var arrCountry = [];
                arrCountry.push(value)
                console.log('arr', arrCountry);
                this.objPages[name] = arrCountry
            } else {
                this.objParams[name] = value;
            }
        this.getdata();
        }
    },
    searchB(e){
      this.objParams.search = e
      this.getdata()
    },
    changelimit: function(limit){
        this.objParams.pagelimit = limit;
        this.getdata();
    },
    getFlag(e){
        return getFlag(e)
    }
},
computed: {
    user() {
        return storeS.userbase
    },
    perms() {
        return storeS.perms
    }
},
mounted() {
    this.eventBus.on('saveTask', (status) => {
    if(status == true) {
        this.getdata();
    }
    })
}
};
</script>

<style scoped>
.tr_etner:hover {
cursor: pointer;
background: #f3f3f9;
}
</style>
