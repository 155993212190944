<script>
// Описуємо компонент за замовчуванням
export default {
  // Компоненти, які використовуються в цьому компоненті
  components: {},
  
  // Властивості компонента
  props: {
    // Заголовок модального вікна
    title: {
      // Тип даних - строка
      type: String,
      // Значення за замовчуванням - порожня строка
      default: "",
    },
    
    // Елементи, які відображаються в модальному вікні
    // items: {
    //   // Тип даних - масив
    //   type: Array,
    //   // Значення за замовчуванням - порожній масив
    //   default: () => {
    //     return [];
    //   },
    // },
  },
};
</script>

<template>
  <!-- Початок блоку заголовка сторінки -->
  <div class="row">
    <div class="col-12">
      <div
        class="page-title-box d-sm-flex align-items-center justify-content-between"
      >
        <!-- Заголовок сторінки -->
        <h4 class="mb-sm-0">
          <!-- Переклад заголовка за допомогою функції $t -->
          {{$t(title)}}
        </h4>

        <!-- Блок праворуч від заголовка -->
        <div class="page-title-right">
          <!-- Коментований код для відображення хлібних крихт -->
          <!-- <ol class="breadcrumb m-0">
            <li
              class="breadcrumb-item"
              v-for="(item, index) in items"
              :key="index"
              :class="{ active: item[0] }"
            > 
              <a href="javascript: void(0);">{{$t(item.text)}}</a>
            </li>
          </ol> -->
        </div>
      </div>
    </div>
  </div>
  <!-- Кінець блоку заголовка сторінки -->
</template>
